<script setup>
import {reactive, ref} from "vue";
import {useRoute, useRouter} from "vue-router";
import {useToast} from "primevue/usetoast";
import {email, required} from "@vuelidate/validators";
import {useVuelidate} from "@vuelidate/core";
import {storeToRefs} from "pinia";
import {MemberStore} from "@/uhas/store/member";

const route = useRoute();
const router = useRouter();
const toast = useToast();

const memberStore = MemberStore();
const {avatar, fullName} = storeToRefs(memberStore);

/**
 * Data
 */
const loading = ref(false);
const blockOverlay = ref(false);

const basicInfoState = reactive({...memberStore.user});
const basicInfoValidation = useVuelidate({
	firstName: { required },
	lastName: { required },
	email: { email },
	campus: { required },
	year: { required }
}, basicInfoState);
const basicInfoSubmitted = ref(false);

const newAvatarFile = ref();
const newAvatarImg = ref(null);

const campus = ref([
	"วิทยาเขต",
	"กระบี่",
	"กรุงเทพ",
	"ชลบุรี",
	"ชัยภูมิ",
	"ชุมพร",
	"เชียงใหม่",
	"ตรัง",
	"เพชรบูรณ์",
	"มหาสารคาม",
	"ยะลา",
	"ลำปาง",
	"ศรีสะเกษ",
	"สมุทรสาคร",
	"สุโขทัย",
	"สุพรรณบุรี",
	"อ่างทอง",
	"อุดรธานี",
]);

/**
 * Computed
 */
// const exness = computed(() => memberStore.user?.exness || tempExness.value || null);

/**
 * Actions
 */
const avatarChange = (evt) => {
	if (evt.target.files && evt.target.files[0]) {
		newAvatarImg.value = URL.createObjectURL(evt.target.files[0]);
		newAvatarFile.value = evt.target.files[0];
	}
}
const saveBasicInfo = async () => {
	basicInfoSubmitted.value = true;
	if (basicInfoValidation.value.$invalid) {
		return
	}

	loading.value = true;
	memberStore.saveBasicInfo({
		user: basicInfoState,
		newAvatarFile: newAvatarFile.value || null,
	}).then(result => {
		memberStore.user = {
			...memberStore.user,
			...result.user
		};

		toast.add({
			severity:"success",
			summary: "Success Message",
			detail: "โปรไฟล์อัพเดตแล้ว",
			life: 10000
		});

		if (route.query.continue === 'exam') {
			setTimeout(() => {
				router.replace({name: "exam"})
			}, 2000)
		}
	}).finally(() => {
		loading.value = false
	})
}
</script>

<style lang="scss">
.block-overlay {
	box-shadow: unset;

	.p-dialog-content {
		background: transparent;
    overflow: hidden;

		circle {
			fill: none;
		}
	}
}
</style>
<style lang="scss" scoped>
:v-deep(.block-overlay) {
	box-shadow: unset;
	.p-dialog-content {
		background: transparent;
    overflow: hidden;
	}
}

.avatar-badge {
	width: 24px;
	height: 24px;
	border: 2px solid white;
	position: absolute;
	bottom: 2%;
	right: 2%;
	border-radius: 100%;
}

::v-deep .p-card-body {
	padding: 2rem;
}
</style>

<template>
	<Toast />
	<ConfirmPopup />
	<Dialog
		v-model:visible="blockOverlay"
		class="block-overlay"
		appendTo="self"
		:closeOnEscape="false"
		:showHeader="false"
		modal
	>
		<ProgressSpinner
			style="width:5vw;height:5vw"
			strokeWidth="8"
			fill="var(--surface-ground)"
			animationDuration=".5s"
		/>
	</Dialog>

	<div class="card widget-user-card pt-4">
		<div class="h-3rem flex justify-content-center">
			<label
				class="absolute w-8rem h-8rem flex justify-content-center"
				for="avatarUpload"
			>
				<img
					class="border-circle"
					:src="newAvatarImg || avatar"
				>
				<span class="avatar-badge bg-white">
					<i class="pi pi-camera"></i>
				</span>
				<input
					type="file"
					id="avatarUpload"
					accept="image/png, image/jpeg"
					class="hidden"
					:onchange="avatarChange"
				/>
			</label>
		</div>
		<div class="user-card-body">
				<div class="user-card-title">
					{{ fullName || memberStore.user?.phone || "???" }}
				</div>
				<div
					v-if="memberStore.user?.firstName"
					class="user-card-subtext"
				>
					{{ memberStore.user?.phone }}
				</div>

				<form @submit.prevent="saveBasicInfo" class="p-fluid">
					<div class="p-fluid grid text-left mt-6">
						<div class="field col-12 md:col-6">
							<div class="p-float-label">
								<InputText
									id="firstName"
									v-model="basicInfoValidation.firstName.$model"
									:class="{'p-invalid':basicInfoValidation.firstName.$invalid && basicInfoSubmitted}"
								/>
								<label
									for="firstName"
									:class="{'p-error':basicInfoValidation.firstName.$invalid && basicInfoSubmitted}"
								>
									ชื่อ *
								</label>
							</div>
						</div>
						<div class="field col-12 md:col-6">
							<div class="p-float-label">
								<InputText
									id="lastName"
									v-model="basicInfoValidation.lastName.$model"
									:class="{'p-invalid':basicInfoValidation.lastName.$invalid && basicInfoSubmitted}"
								/>
								<label
									for="lastName"
									:class="{'p-error':basicInfoValidation.lastName.$invalid && basicInfoSubmitted}"
								>
									นามสกุล *
								</label>
							</div>
						</div>
						<div class="field col-12 md:col-6">
							<div class="p-float-label">
								<InputText
									id="email"
									v-model="basicInfoValidation.email.$model"
									:class="{'p-invalid':basicInfoValidation.email.$invalid && basicInfoSubmitted}"
								/>
								<label
									for="email"
									:class="{'p-error':basicInfoValidation.email.$invalid && basicInfoSubmitted}"
								>
									อีเมล
								</label>
							</div>
						</div>
						<div class="field col-12 md:col-6">
							<div class="p-float-label">
								<InputText
									id="phone"
									v-model="basicInfoState.phone"
									disabled
								/>
								<label for="phone">เบอร์โทรศัพท์ *</label>
							</div>
						</div>
						<div class="field col-12 md:col-6">
							<div class="p-float-label">
								<Dropdown
									v-model="basicInfoState.campus"
									:options="campus"
									:class="{'p-invalid':basicInfoValidation.campus.$invalid && basicInfoSubmitted}"
									id="campus"
								/>
								<label
									for="campus"
									:class="{'p-error':basicInfoValidation.campus.$invalid && basicInfoSubmitted}"
								>
									วิทยาเขต *
								</label>
							</div>
						</div>
						<div class="field col-12 md:col-6">
							<div class="p-float-label">
								<Dropdown
									v-model="basicInfoState.year"
									:options="[1,2,3,4]"
									:class="{'p-invalid':basicInfoValidation.year.$invalid && basicInfoSubmitted}"
									id="year"
								/>
								<label
									for="year"
									:class="{'p-error':basicInfoValidation.year.$invalid && basicInfoSubmitted}"
								>
									ชั้นปี *
								</label>
							</div>
						</div>
					</div>
				</form>
		</div>
		<div class="text-left p-5 pt-0">
			<Button
				:label="loading ? 'กำลังบันทึก...' : 'บันทึก'"
				:loading="loading"
				@click="saveBasicInfo"
			/>
		</div>
	</div>
</template>
